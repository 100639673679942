.wrap {
  padding: 120px 0;
  font-size: 62px;
  color: #888;
  width: 400px;
  font-family: "Barlow";
  margin: 0 auto;
  text-align: center;
}
input {
  font-family: "barlow";
  font-weight: 300;
  border: 0;
  border-bottom: 1px solid #f55345;
  width: 100%;
  height: 36px;
  font-size: 26px;
}
.btn {
  font-family: "barlow";
  background-color: #f55345;
  border-radius: 20px;
  padding: 10px 25px;
  color: #fff;
  box-shadow: 0px 18px 13px -6px rgba(0, 0, 0, 0.2);
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
}

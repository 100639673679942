* {
  font-family: "barlow";
}
.header {
  background-color: #f2e2ce;
  padding: 0;

  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  z-index: 4;
}
.header__logo {
  vertical-align: middle;
}
.logoImage {
  height: 90px;
  width: 240px;
  margin-top: 0;
}
.header__el {
  display: block;
  padding: 10px 20px;
  border-top: 1px solid #ededed;
}
.appstyle {
  display: inline-block;
}

.header .btn--white,
.header .btn--white:hover {
  padding: 0;
  border: 0;
  box-shadow: none;
  color: #fff;
  background-color: transparent;
}
.header__el--blue {
  background-color: #f55345;
}

.header {
  padding: 30px 25px;
}
.header__elenco {
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline-block;
}
.header__menu {
  float: right;
  vertical-align: middle;
  margin-right: 20px;
}
.header__el {
  padding: 0 25px;
  display: inline-block;
}
.header__img {
  display: inline-block;
  vertical-align: middle;
}

.header__logo,
.header__menu {
  display: inline-block;
  vertical-align: middle;
}
.header__title {
  font-size: 18px;
  margin: 0;
  margin-left: 10px;
  display: inline-block;
  line-height: 18px;
  vertical-align: middle;
}

.header__light {
  color: #8198ae;
}
.header__link {
  color: #1f4568;
  font-size: 14px;
  text-decoration: none;
}
.header__link:hover {
  text-decoration: none;
  color: #f55345;
}
.navbar-toggle .icon-bar {
  background-color: #8198ae;
}
.navbar-toggle {
  margin: 5px 0;
}
.btn--white {
  text-decoration: none;
}
.btn {
  background: #8a2b06;
}

.summary {
    text-align: center;
    max-width: 45rem;
    width: 30%;
    margin: auto;
    margin-top: 50px;
    position: relative;
    background-color: #383838;
    color: white;
    border-radius: 14px;
    padding: 1rem;
    box-shadow: 0 1px 18px 10px rgba(0, 0, 0, 0.25);
  
     font-family: "Playfair Display";
  }
  
  .summary h2 {
    font-size: 2rem;
    margin-top: 0;
  }
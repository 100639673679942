.button {
  background-color: #f55345;
  border-radius: 20px;
  margin-right: 30px;
  padding: 20px 25px;
  color: #fff;
  box-shadow: 0px 18px 13px -6px rgba(0, 0, 0, 0.2);
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
}

.button:hover,
.button:active {
  background-color: #f55345;
}

@keyframes bump {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(0.9);
  }
  30% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}

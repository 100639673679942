.pos {
    max-width: 60rem;
    width: 50%;
    margin: 2rem auto;
    animation: meals-appear 1s ease-out forwards;
   
  }
  
  .pos ul {
    list-style: none;
    margin: 0;
    padding: 0;
    color: black;
    font-family: "Playfair Display";

  }
  
.positionbox {
    display: flex;
    justify-content: space-between;
    margin: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ccc;
  }
  
  .positionbox h3 {
    margin: 0 0 0.25rem 0;
  }